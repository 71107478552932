/* start vars: m-personalization */
/* line 13, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization {
	width: auto;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
}

@media only screen and (max-width: 999em) {
	/* line 13, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
	.m-personalization {
		line-height: normal;
	}
}

/* line 23, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization .content-box__title {
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* line 30, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar {
	margin-bottom: 10px;
	width: 100%;
}

/* line 35, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--basic {
	display: inline-block;
}

@media only screen and (min-width: 60em) and (max-width: 59.999em) {
	/* line 42, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
	.m-personalization--basic .save-text,
	.m-personalization--basic .saved-text {
		display: none;
	}
}

/* line 75, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list {
	position: relative;
	display: inline-block;
}

/* line 79, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list .list-bare__item {
	position: relative;
}

/* line 83, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list .myportfolio__flyout {
	max-width: 100%;
}

/* line 87, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list .icon-arrow-right-full {
	top: 50%;
	transform: translate(-0, -50%);
	right: 10px;
	color: rgb(var(--color-global-secondary));
	position: absolute;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__list .icon-arrow-right-full {
	right: auto;
	left: 10px;
}

/* line 95, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list {
	display: block;
}

/* line 98, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper {
	padding: 10px;
}

/* line 101, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper--savesearch {
	padding: 15px 10px;
}

/* line 106, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper--savesearch .personalization__save-link .save-text {
	display: inline;
}

/* line 110, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper--savesearch .personalization__save-link .saved-text {
	display: none;
}

/* line 115, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper--savesearch .personalization__save-link.is-saved .save-text {
	display: none;
}

/* line 119, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .content-box__wrapper--savesearch .personalization__save-link.is-saved .saved-text {
	display: inline;
}

/* line 127, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .myportfolio__link {
	padding-right: 10px;
	text-transform: capitalize;
	font-weight: 400;
	position: relative;
	font-size: 1em;
	line-height: normal;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-personalization--sidebar .personalization__list .myportfolio__link {
	padding-right: initial;
	padding-left: 10px;
}

/* line 135, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.m-personalization--sidebar .personalization__list .myportfolio__link:after {
	top: 50%;
	transform: translate(-0, -50%);
	border-left: 4px solid;
	right: 0;
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-personalization--sidebar .personalization__list .myportfolio__link:after {
	border-left: 0;
	border-right: 4px solid;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-personalization--sidebar .personalization__list .myportfolio__link:after {
	right: auto;
	left: 0;
}

@media only screen and (max-width: 999em) {
	/* line 75, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
	.personalization__list {
		display: block;
	}
}

/* line 155, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list-item {
	padding-top: 5px;
	padding-bottom: 5px;
}

/* line 159, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list-item--bordered {
	border-bottom: 1px solid rgb(var(--color-global-body));
}

/* line 163, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list-item--hspaced {
	padding-left: 20px;
	padding-right: 20px;
}

/* line 168, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__list-item--spaced {
	padding-top: 0;
	padding-bottom: 10px;
}

/* line 174, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.personalization__save-icon {
	margin-left: 10px;
	font-size: 1.34em;
	color: rgb(var(--color-global-secondary));
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .personalization__save-icon {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .personalization__save-icon {
		margin-left: 0;
	}
}

/* line 182, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.myportfolio__search-title, .myportfolio__search-locations, .myportfolio__address, .myportfolio__price {
	flex: 1 1 auto;
}

/* line 189, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.myportfolio__search-locations, .myportfolio__price {
	text-align: right;
	padding-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .myportfolio__search-locations, .is-rtl .myportfolio__price {
	text-align: left;
}

/* line 196, scss/80-themes/AffinityDefault/70-modules/basemyportfoliomodulebase/module.scss */
.signout__confirmation-text {
	text-align: center;
	margin-bottom: 20px;
}

/*# sourceMappingURL=../../../../../true */